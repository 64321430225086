import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

export const useLocalStorageState = <S>(localStorageKey: string, initialState: S | (() => S)) => {
  const [initialStateInternal] = useState(initialState);
  const [state, setState] = useState((JSON.parse(localStorage.getItem(localStorageKey) || 'null') as S) || initialStateInternal);

  useEffect(() => {
    const localState = JSON.parse(localStorage.getItem(localStorageKey) || 'null') as S;
    setState(localState === undefined || localState === null ? initialStateInternal : localState);
  }, [initialStateInternal, localStorageKey]);

  const localStorageKeyRef = useRef(localStorageKey);

  useEffect(() => {
    localStorageKeyRef.current = localStorageKey;
  }, [localStorageKey]);

  const setStateInternal = useCallback<Dispatch<SetStateAction<S>>>(
    (value) => {
      const localState = JSON.parse(localStorage.getItem(localStorageKeyRef.current) || 'null') as S;
      const newValue: S =
        typeof value === 'function'
          ? (value as CallableFunction)(localState === undefined || localState === null ? initialStateInternal : localState)
          : value;

      window.localStorage.setItem(localStorageKeyRef.current, JSON.stringify(newValue));
      setState(newValue);
    },
    [initialStateInternal],
  );

  return [state, setStateInternal] as const;
};
